<template>
  <!-- Hero -->
  <div class="Hero">
    <div class="site-container">
      <div class="HeroAtc">
        <div class="HeroBg">
            <img class="HeroBg-pc" src="images/view/main/hero_star-min.png" alt="">
            <img class="HeroBg-mo" src="images/view/main/hero_star_mo.png" alt="">
          </div>
        <div class="HeroText">
          <div class="HeroText-cate">STA 기질검사</div>
          <h2 class="HeroText-title">나만의 기질을<br />찾아보아요</h2>
          <p class="HeroText-sub">STA 기질검사는 <br class="moClear" />나만의 타고난 기질을 발견하는 검사입니다.<br />기질 유형을 발견하고, 마주하며, 직면하여 나를 온전히 이해하는 시간을 가져보세요.</p>
          <div class="HeroBtn">
            <router-link class="HeroBtn-link" :to="{name: 'TestIntro'}">검사 시작</router-link>
          </div>
        </div>
        <button type="button" class="HeroDown" @click="ScrollDown"><span class="ab-text">Scroll Down</span></button>
      </div>
    </div>
  </div>
  <!-- .Hero -->
  <!-- MainCnt -->
  <div class="MainCnt">
      <!-- 위드유 프로그램 -->
      <div class="MainSec MainProgram">
        <div class="site-container">
          <div class="MainProgram-inner">
            <div class="MainProgramHeader">
              <h3 class="SecHeadTitle">위드유 프로그램</h3>
              <p class="SecHeadSub">위드유 프로그램을 소개합니다.</p>
            </div>
            <div class="MainProgramList">
              <ul class="MainProgramList-inner">
                <li class="MainProgramList-item">
                  <card-post :postUrl="postUrl0" :postTitle="postTitle0" :postImg="postImg0"></card-post>
                </li>
                <li class="MainProgramList-item">
                  <card-post :postUrl="postUrl1" :postTitle="postTitle1" :postImg="postImg1"></card-post>
                </li>
                <li class="MainProgramList-item">
                  <card-post :postUrl="postUrl2" :postTitle="postTitle2" :postImg="postImg2"></card-post>
                </li>
              </ul>
              <ul class="MainProgramList-inner">
                <li class="MainProgramList-item">
                  <card-post :postUrl="postUrl3" :postTitle="postTitle3" :postImg="postImg3"></card-post>
                </li>
                <li class="MainProgramList-item">
                  <card-post :postUrl="postUrl4" :postTitle="postTitle4" :postImg="postImg4"></card-post>
                </li>
                <li class="MainProgramList-item">
                  <card-post :postUrl="postUrl5" :postTitle="postTitle5" :postImg="postImg5"></card-post>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- 위드유 프로그램 -->
      <!-- 위드유 소개 -->
      <div class="MainAbout">
        <div class="MainAboutBg">
          <img class="MainAboutBg-img" src="images/view/main/main_about_bg.png" alt="">
        </div>
        <div class="site-container">
          <div class="MainAboutText">
            <div class="MainAboutText-sub">위드유치료교육 연구소는</div>
            <div class="MainAboutText-title">누구나 가치있는 존재로서 자기다운 모습으로 살아갈 수 있도록 지원합니다.<br />우리의 모습속에서 마음을 연결하고, 어려움을 해결하고, <br class="pcClear" />사랑을 회복하도록 함께하겠습니다.</div>
            <div class="MainAboutBtn">
              <router-link :to="{name: 'Introduction'}" class="MainAboutBtn-link">자세히 보기</router-link>
              <div class="MainAboutBtn-item"><download-pdf-button :pdf-url="pdfUrl" :pdf-file-name="pdfFileName" /></div>
            </div>
          </div>
        </div>
      </div>
      <!-- .위드유 소개 -->
      <!-- 위드유 소식 -->
      <div class="MainSec MainNotice j-Ani">
        <div class="site-container">
          <div class="MainNotice-inner">
            <div class="MainNoticeHeader">
                <h3 class="SecHeadTitle">위드유 소식</h3>
            </div>
            <notice-post :mainNotice="mainNotice" :noticeDomain="noticeDomain"></notice-post>
            <div class="MainNoticeList">
              <ul class="MainNoticeList-inner">
                <li class="MainNoticeList-item">
                  <div class="MainNoticeItem Qna">
                    <router-link :to="{name: 'Contact'}" class="MainNoticeItem-link">
                      <div class="MainNoticeItemText">
                        <h4 class="MainNoticeItemText-title">문의하기</h4>
                        <p class="MainNoticeItemText-sub">강의와 교육 관련하여 궁금하신 점을 남겨주세요.</p>
                      </div>
                    </router-link>
                  </div>
                </li>
                <li class="MainNoticeList-item">
                  <div class="MainNoticeItem Reservation">
                    <a href="/Program/Temperament" class="MainNoticeItem-link">
                      <div class="MainNoticeItemText">
                        <h4 class="MainNoticeItemText-title">예약하기</h4>
                        <p class="MainNoticeItemText-sub">위드유치료교육연구소의 다양한 프로그램을 예약하세요.</p>
                      </div>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- .위드유 소식 -->
       <!-- 연간일정 -->
       <div id="withYoucalender" class="MainSec MainCalender j-Ani">
        <div class="site-container">
          <div class="MainNotice-inner">
            <div class="MainNoticeHeader">
                <h3 class="SecHeadTitle">민간 자격 교육 일정</h3>
            </div>
            <div class="MainCalenderSec">
              <div class="ColorSet">
                <ul class="ColorSet-list">
                  <li class="ColorSet-item"><span class="ColorSet-txt color1">기초</span></li>
                  <li class="ColorSet-item"><span class="ColorSet-txt color2">초급</span></li>
                  <li class="ColorSet-item"><span class="ColorSet-txt color3">중급</span></li>
                  <li class="ColorSet-item"><span class="ColorSet-txt color4">고급</span></li>
                  <li class="ColorSet-item"><span class="ColorSet-txt color7">기질분석상담사 1급</span></li>
                  <li class="ColorSet-item"><span class="ColorSet-txt color5">기질분석상담사 2급</span></li>
                  <li class="ColorSet-item"><span class="ColorSet-txt color6">STA 기질부모교육 전문가</span></li>
                  <li class="ColorSet-item"><span class="ColorSet-txt color8">양육의 지혜</span></li>
                </ul>
              </div>
              <FullCalendar :options="calendarOptions">
              <template v-slot:eventContent='info'>
                <a :href="info.event.url" class="CalendarEventTitle">
                  <span class="CalendarEventTitle-color" :class="info.event.extendedProps.division"></span>
                  <span class="CalendarEventTitle-title">{{ info.event.title }}</span>
                  <span class="CalendarEventTitle-flag">{{ info.event.extendedProps.flag }}</span>
                  <span class="CalendarEventTitle-count">{{ info.event.extendedProps.rounds }}</span>
                </a>
              </template>
            </FullCalendar>
            </div>
          </div>
        </div>
      </div>
      <!-- .연간일정 -->
      <!-- 무료 강의 -->
      <div class="MainSec MainStudy">
        <div class="site-container">
          <div class="MainStudyHeader">
              <h3 class="SecHeadTitle">특별 강의</h3>
          </div>
          <div class="MainStudyBody">
            <study-slide :slides="slides" :slideDomain="slideDomain"></study-slide>
          </div>
        </div>
      </div>
      <!-- .무료 강의 -->
  </div>
  <!-- .MainCnt -->
  <template v-if="TemplateEvtPop">
      <event-popup></event-popup>
  </template>
</template>
<script>
import {computed, reactive } from 'vue'
import FullCalendar from '@fullcalendar/vue3'//npm install @fullcalendar/vue3 @fullcalendar/core @fullcalendar/daygrid
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';//npm install --save @fullcalendar/core @fullcalendar/list
import interactionPlugin from '@fullcalendar/interaction'
import CardPost from "../components/cardpost/cardpost";
import { useHead } from '@vueuse/head'
import NoticePost from "../components/noticepost/noticepost";
import StudySlide from "../components/multislide/multislide";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import DownloadPdfButton from '../components/pdfdownload/pdfdownload.vue';
import EventPopup from "../components/eventpopup/eventpopup.vue";

gsap.registerPlugin(ScrollTrigger);
export default {
  setup() {
      const siteData = reactive({
      title: `WithYou 위드유치료교육연구소`,
      description: `STA 기질검사는 나만의 타고난 기질을 발견하는 검사입니다.`,
      canonical: `https://withyoulove.co.kr/`,
      })
      useHead({
          title: computed(() => siteData.title),
          meta: [
                  {
                      name: `description`,
                      content: computed(() => siteData.description),
                  },
          ],
          link: [
            {
                rel: `canonical`,
                content: computed(() => siteData.canonical),
            },
          ]
      })
  },
  components:{
    CardPost,
    NoticePost,
    StudySlide,
    FullCalendar,
    DownloadPdfButton,
    EventPopup
  },
  data() {
    return {
      //이벤트팝업 노출
      TemplateEvtPop: true,

      //위드유 소개
      postUrl0:{name: 'TestIntro'},
      postTitle0:'STA 기질검사',
      postImg0:'images/view/main/main_program_0.png',

      //위드유 프로그램 - 기질평가 프로그램
      postUrl1:{name: 'Temperament'},
      postTitle1:'기질평가\n프로그램',
      postImg1:'images/view/main/main_program_1.png',

      //위드유 프로그램 - 특별 프로그램
      postUrl2:{name: 'Special'},
      postTitle2:'특별\n프로그램',
      postImg2:'images/view/main/main_program_2.png',

      //위드유 프로그램 - 민간자격 프로그램
      postUrl3:{name: 'PrivateQualification'},
      postTitle3:'민간자격\n프로그램',
      postImg3:'images/view/main/main_program_3.png',

      //위드유 프로그램 - 양육의 지혜 프로그램
      postUrl4:{name: 'WisdomOfParenting'},
      postTitle4:'양육의 지혜\n프로그램',
      postImg4:'images/view/main/main_program_4.png',

      //위드유 프로그램 - 심리 치료 및 상담
      postUrl5:{name: 'Psychotherapy'},
      postTitle5:'심리 치료\n프로그램',
      postImg5:'images/view/main/main_program_5-1.png',

      //위드유 소식
      mainNotice:[],
      noticeDomain:'/About/Notice/',
      apiMainNotice: 'https://withyoulove.co.kr:8000/api/blogList/?cat=5&size=3',
      
      //무료 강의
      slides:[],
      slideDomain:'/Information/SpecialLecture/',
      apiSlideNotice: 'https://withyoulove.co.kr:8000/api/blogList/?type=main&size=3&cat=6',

      //pdf
      pdfUrl: '../../uploads/TalkFile_위드유치료교육연구소-소개.pdf',
      pdfFileName: 'TalkFile_위드유치료교육연구소-소개.pdf',

      //캘린더
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, listPlugin ],
        initialView: window.innerWidth > 747 ? 'dayGridMonth':'listMonth',
        dateClick: this.handleDateClick,
        locale: 'ko',
        displayEventTime: false,
        contentHeight: 'auto',
        allDaySlot: false,
        headerToolbar:{
          start: 'prev', 
          center: 'title', //, today 적용시
          end: 'next' 
        },
        dayCellContent:function(info){
          var number = document.createElement("a");
          number.classList.add("fc-daygrid-day-number");
          number.innerHTML = info.dayNumberText.replace("일","").replace("日","");
          if(info.view.type === "dayGridMonth"){
            return{
              html:number.outerHTML
            };
          }
          return{
            domNodes:[]
          }
        },
        events: function (fetchInfo, successCallback, failureCallback) {
          // 현재 월의 시작일과 마지막일을 가져옵니다.
          const startDate = new Date(fetchInfo.startStr);
          const endDate = new Date(fetchInfo.endStr);

          // 날짜에 1일을 추가하는 함수입니다.
          const addDays = (date, days) => {
              const newDate = new Date(date);
              newDate.setDate(newDate.getDate() + days);
              return newDate;
          };

          // 날짜를 YYYY-MM-DD 형식으로 변환합니다.
          const formatDate = (date) => {
              const year = date.getUTCFullYear();
              const month = String(date.getUTCMonth() + 1).padStart(2, '0');
              const day = String(date.getUTCDate()).padStart(2, '0');
              return `${year}-${month}-${day}`;
          };

          // 날짜에 1일을 추가합니다.
          const adjustedStartDate = addDays(startDate, 1);
          const formattedStartDate = formatDate(adjustedStartDate);
          const formattedEndDate = formatDate(endDate);

          // API 요청 URL을 동적으로 설정합니다.
          const url = `https://withyoulove.co.kr:8000/api/Schedule/?startdate=${formattedStartDate}&enddate=${formattedEndDate}`;

          fetch(url)
              .then(response => response.json())
              .then(data => {
                  const events = data.events.map(event => ({
                      title: event.title,
                      start: event.meeting_at, // 'meeting_at'을 'start'로 사용
                      url: event.link_url, // 'link_url'을 'url'로 사용
                      extendedProps: {
                          division: event.division, // 추가적인 정보로 'division'
                          flag: event.flag, // 추가적인 정보로 'flag'
                          rounds: event.rounds // 추가적인 정보로 'rounds'
                      }
                  }));
                  successCallback(events);
              })
              .catch(error => {
                  console.error('Error fetching events:', error);
                  failureCallback(error);
              });
        },


        eventClick: function(info) {
          info.jsEvent.preventDefault();

          if (info.event.url) {
            window.open(info.event.url,"_self");
          }
        }
      },
    };
  },
  mounted() {
    //실행
    this.$nextTick(this.scrollAniView('j-Ani'));
    document.body.classList.add('page-home');

    ScrollTrigger.refresh(true);
    
    this.t = gsap.timeline({
      scrollTrigger: {
          trigger: ".MainAbout",
          start: "top bottom",
          end: "50%",
          scrub: true,
      }
      })
      .from(".MainAboutBg", { y : '-20%'})
      this.mainNoticeMore();
      this.mainSlideMore();
  },
  unmounted() {
    this.$nextTick(this.scrollAniView('j-Ani'));
    document.body.classList.remove('page-home');
    this.t.kill(); 
  },
  methods: {
    //스크롤 다운 버튼 클릭시 스크롤
    ScrollDown:function(){
      const offset = document.getElementsByClassName("MainCnt")[0].offsetTop;
      window.scroll({top:offset, behavior: 'smooth'})
    }
  }
}
</script>
